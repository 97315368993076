import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LayoutService } from '../../../layout/services/layout.service';
import { ActivatedRoute, Router } from '@angular/router';
import { InvoiceService } from '../../../line-item-entry/services/invoice.service';
import { Invoice } from '../../../line-item-entry/models/invoice.interface';
import { MatDialog } from '@angular/material/dialog';
import { PaymentDialogComponent } from './payment-dialog/payment.dialog';
import { AccountService } from '../../../configuration-management/services/account.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { StatementService } from 'src/app/list-communications/services/statements.service';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation.dialog';
import { ListCommunicationsService } from 'src/app/list-communications/services/list-communications.service';
import FileSaver from 'file-saver';
import { ItemLogDialogComponent } from 'src/app/shared/item-log-dialog/item-log.dialog';
import { LogPage } from 'src/app/configuration-management/models/logs.models';
import { InvoiceStatusValue } from 'src/app/shared/invoice-status/invoice-status-value';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent implements OnInit {

  @Input() invoiceId: string;
  keepRevision: boolean = false;
  public invoice: Invoice = null;
  public reloadLineItemEntry = false;

  constructor(
    public layoutService: LayoutService,
    public accountService: AccountService,
    private route: ActivatedRoute,
    private invoiceService: InvoiceService,
    public statementService: StatementService,
    public listCommunicationsService: ListCommunicationsService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public router: Router
  ) { }

  ngOnInit() {
    this.getInvoice();
  }

  getInvoice() {

    let id = this.route.snapshot.paramMap.get('id');
    if (this.invoiceId) {
      id = this.invoiceId;
    }

    this.invoiceService.getInvoice(id).subscribe(
      response => {
        let count = 1;
        response.lineItems.forEach(x => {
          x.position = count;
          count++;
        });


        this.invoice = response;
        this.reloadLineItemEntry = false;
        this.setRoute();
        // this.test(this.invoice.client.id, this.invoice.id);
      }
    );
  }

  setRoute() {
    this.layoutService.setRoute(`dashboard/`,
      `<span><span class="left-icon"></span> Dashboard</span>`);
  }

  payInvoiceDialog() {
    const dialogRef = this.dialog.open(PaymentDialogComponent, {
      width: '300px',
      data: {
        clientId: this.invoice.client.id,
        paymentId: null
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getInvoice();
      }
    });
  }

  reload() {
    this.reloadLineItemEntry = false;
    this.ngOnInit();
  }

  deleteInvoice() {

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '300px',
      data: {
        header: 'Delete Invoice',
        body: `This invoice will be deleted.`,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // send
        this.invoiceService.deleteInvoice(this.invoice.id).subscribe(x => {
          this.invoice = null;
          this.snackBar.open('Invoice was deleted.', 'Success', {
            duration: 2000,
          });
        });
      } else {
        // cancel
        this.snackBar.open('Operation cancelled.', '', {
          duration: 2000,
        });
      }
    });
  }

  send() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '300px',
      data: {
        header: 'Send Invoice Email',
        body: `This invoice will be sent as an email to ${this.invoice.client.name}.`,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // send
        this.statementService.sendInvoiceEmail(this.invoice.client.id, this.invoice.id).subscribe(x => {
          this.snackBar.open('Invoice email sent.', 'Success', {
            duration: 2000,
          });
        });
      } else {
        // cancel
        this.snackBar.open('Operation cancelled.', '', {
          duration: 2000,
        });
      }
    });
  }

  print() {
    this.listCommunicationsService.printInvoiceEmail(this.invoice.id).subscribe(res => {
      FileSaver.saveAs(res, 'Invoice' + '.pdf');
    },
    error => {
      this.snackBar.open('Unable to retrieve content.', 'Error', {
        duration: 2000
      });
    });
  }

  finalize() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      height: 'auto',
      width: 'auto',
      data: {
        header: 'Send Invoice Email',
        body: `This communcation will be sent as an email to ${this.invoice.client.name}.`,
        emailOption: true,
      }
    });

    dialogRef.afterClosed().subscribe(response => {
      if (response === 'true') {
        // Send comm to client
        this.statementService.finalize(this.invoice.id).subscribe(x => {

          this.invoice.closed = true;
          const snackBarRef = this.snackBar.open('Invoice finalized.', 'Success', {
            duration: 2000,
          });
          snackBarRef.afterDismissed().subscribe(dismissed => {

            this.snackBar.open('Sending client communication..', '', {
              duration: 2000,
            });
            this.statementService.sendInvoiceEmail(this.invoice.client.id, this.invoice.id).subscribe( y => {

              this.snackBar.open('Client communication sent.', 'Success', {
                duration: 2000,
              });
            },
              error => {
                this.snackBar.open('Client communication could not be sent.', 'Error', {
                  duration: 2000,
                });
              });
          });
          this.getInvoice();
        },
          error => {
            this.snackBar.open('Invoice could not be finalized.', 'Error', {
              duration: 2000,
            });
          });
      } else if (response === 'no-email') {
        this.statementService.finalize(this.invoice.id).subscribe(x => {

          this.invoice.closed = true;
          this.snackBar.open('Invoice finalized.', 'Success', {
            duration: 2000,
          });
          this.getInvoice();
        });
      } else {
        this.snackBar.open('Operation cancelled.', '', {
          duration: 2000,
        });
      }
    });
  }

  addSpace(s) {
    return s.replace(/([A-Z])/g, ' $1').trim();
  }

  update(invoice: Invoice) {

    this.invoice = invoice;
  }

  setInvoiceStatus() {
    this.invoiceService.setInvoiceStatus(this.invoice.id, this.invoice.invoiceStatus).subscribe(success => {

      this.snackBar.open('Invoice status was updated.', 'Success', {
        duration: 2000,
      });
    }, error => {
      this.snackBar.open('Invoice could not be updated.', 'Error', {
        duration: 2000,
      });
      this.invoice.invoiceStatus = InvoiceStatusValue.NotReady;
    });
  }

  setKeepRevisions() {
    if(this.keepRevision){
      this.invoiceService.trackRevisions(this.invoice.id).subscribe(success => {
        this.snackBar.open('Invoice revisions tracked', 'Success', {
          duration: 2000,
        });
      }, error => {
        this.snackBar.open('Invoice could not be tracked.', 'Error', {
          duration: 2000,
        });
        this.invoice.invoiceStatus = InvoiceStatusValue.NotReady;
      });
    }
  }

  viewLogs() {
    const logPage = new LogPage();
    logPage.itemId = this.invoiceId;
    logPage.logType = 'LineItem';

    const dialogRef = this.dialog.open(ItemLogDialogComponent, {
      height: 'auto',
      width: 'auto',
      panelClass: 'log-panel',
      data: logPage
    });
  }
}
