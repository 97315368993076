import {LineItem} from '../../line-item-entry/models/line-item.interface';
import {Page, UserAccount} from 'src/app/configuration-management/models/user.interface';
import {LabRequestResult} from 'src/app/whiteboard/models/order.interface';
import {Branch} from 'src/app/configuration-management/models/branch.interface';
import {CommunicationViewModel} from '../../client-management/models/communication.interface';
import {PatientMedicalRecordBindingModel} from '../../patient-management/models/patient.interface';

export class Request {
    lineItem: LineItem;
    lineItemId: string;
    requestStatus: string;
    requestNotes: string;
    orderDate: Date;
    completedDate: Date;
    requestType: string;
    isCompleted: boolean;
    fulfiller: UserAccount;
    requestedUser: UserAccount;
    requestedUserId: string;
    id: string;
    requestActionStatus: string;
    clientName: string;
    patientName: string;
    pickedUp: boolean;
    assignedUserId: string;
    assignedUser: UserAccount;
    createdByUserId: string;
    createdByUser: UserAccount;
    dateOfProcedure: Date;
    sendTo: string;
    pickUpNextDay: boolean;
    clientId: string;
    patientId: string;
    medicationsSupplies: MedicationsSuppliesRequest[];
    s3FileId: string;
    requestCommunicationStatus: string;
    patientMedicalRecord: PatientMedicalRecordBindingModel;
    // Refill Data is for Medication Pickup Requests
    refillStatus: string;
    refillDate: Date;
    refillStatusUserId: string;
    refillStatusDate: Date;
    emailNotes: string;
    branch: Branch;
    addToLabQueue: boolean;
    patientIdNumber: string;
    heskaTests: [string];
    doctorList: UserAccount[];
    additionalContacts: string[];
    patientNumber: string;
    clientNumber: string;
    notes: string;
    healthCertificateStatus: string;
    careOfRemainsStatus: string;
    cremationStatus: string;
    keepsakeStatus: string;
    responseStatus: ResponseStatus;
    preferredContactMethod: string;
    urgency: string;
    messageNumber: number;
    communications: CommunicationViewModel[];
}

export class CommunicationRequest extends Request {
    receiverAddress: string;
    receiverPhone: string;
    senderAddress: string;
    preferredContactMethod: PreferredContactMethod;
    messageNumber: number;
    urgency: CommunicationRequestUrgency;
    subject: string;
    textBody: string;
    assignedUserIds: string[];
    notifyViaText: boolean;
    notifyViaEmail: boolean;
}

export class MedicationsSuppliesRequest {
    medicationSupplyId: string;
    quantity: number;
    patientId: string;
    id: string;
    name: string;
    note: string;
}

export class LabResultRequest {
    request: Request;
    labResults: LabRequestResult[];
}

class RequestPage extends Page {
  branchIds: string[];
  serviceId: string;
  medicationId: string;
  medicationStatus: string;
}

export class RequestPageModel {
    requests: Array<Request>;
    page = new RequestPage();
}

export class RequestFilters {
    requesters: RequestFilter[] = [];
}

export class RequestFilter {
    name: string;
    userId: string;
}

export class SendMedicalRecordBindingModel {
    recipient: string;
    sendToClient: boolean;
    sendToDoctor: boolean;
    clientId: string;
    patientId: string;
    requestId: string;
    type: string;
    // Added recordId in case this is used in other places
    recordId: string;
}

export class RefillsDialogData {
    IsEditable: boolean;
    LineItem: LineItem;
}

export class OrphanPage extends Page { }

export class OrphanHeskaTests {
    orphanTests: OrphanHeskaTest[];
    totalElements: number;
}

export class OrphanHeskaTest {
    id: string;
    xmlResults: string;
    testType: string;
    testDate: string;
    testPatientId: string;
    testSampleId: string;
    testSpecies: string;
    results: TestResult[];
}

export class TestResult {
    testName: string;
    testValue: string;
    testUnits: string;
}

export enum RequestType {
  lab = 'Lab',
  medications = 'Medications',
  service = 'Service',
  healthCertificate = 'HealthCertificate',
  careOfRemains = 'CareOfRemains',
  communication = 'Communication',
  general = 'General',
  xray = 'XRay'
}

export enum LabStatus {
  all = 'All',
  pending = 'Pending',
  labUploaded = 'Lab Uploaded',
  complete = 'Complete',
  wip = 'WIP',
  cancelled = 'Cancelled'
}

export enum CommunicationRequestUrgency {
  info = 'Info',
  asap = 'ASAP',
  emergency = 'Emergency'
}

export enum PreferredContactMethod {
  email = 'Email',
  phone = 'Phone'
}

export enum ResponseStatus {
  NeedsResponse = 'Needs Response',
  LeftMessage = 'Left Message',
  Resolved = 'Resolved',
  InternalResponse = 'Internal Response',
}

interface ResponseStatusDisplay {
  value: ResponseStatus;
  displayText: string;
}

export function EnumerateResponseStatus(): ResponseStatusDisplay[] {
  return Object.keys(ResponseStatus)
    .filter((value) => isNaN(Number(value)))
    .map((value) => {
      return { value, displayText: ResponseStatus[value]} as ResponseStatusDisplay;
    });
}
