import { Component, OnInit, Inject, Output, ChangeDetectorRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LineItem } from '../../../../../line-item-entry/models/line-item.interface';
import { EventEmitter } from '@angular/core';
import { Client } from 'src/app/client-management/models/client.interface';
import { Patient } from 'src/app/patient-management/models/patient.interface';

@Component({
  selector: 'app-print-dialog',
  templateUrl: './print.dialog.html',
  styleUrls: ['./print.dialog.scss']
})

export class PrintDialogComponent implements OnInit {
  @Output() lineItemsToPrint = new EventEmitter<LineItem[]>();

  lineItems: any;
  selectedItem: LineItem;
  selectedInstruction: string;
  selectedClient: Client;
  selectedPatient: Patient;
  printItems: LineItem[];
  constructor(
    public dialogRef: MatDialogRef<PrintDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public snackBar: MatSnackBar,
    public cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.selectedClient = this.data.selectedClient;
    this.selectedPatient = this.data.selectedPatient;
    this.lineItems = this.data.selectedItems;
    if (this.lineItems.length > 0) {
      this.setSelected(this.lineItems[0]);

      this.selectedInstruction = this.data.selectedItems[0].instructions;

    }
  }

  onNoClick() {
    this.dialogRef.close();
  }

  setSelected(selected: LineItem) {
    this.selectedItem = selected;
    this.selectedInstruction = selected.instructions;
  }

  saveInstruction() {
    this.selectedItem.instructions = this.selectedInstruction;
  }


  print() {
    // Ensure changes are saved before printing
    if (this.lineItems.length > 0) {
      this.saveInstruction();
    }

    for (const item of this.lineItems) {
      item.isPrinted = true;
    }

    if (this.data.isWhiteboard) {
      this.printItems = this.lineItems;
      this.cdr.detectChanges();
      const printContent = document.getElementById('print');
      const WindowPrt = window.open('', '', 'left=0,top=0,toolbar=0,scrollbars=0,status=0');
      WindowPrt.document.write(printContent.innerHTML);
      WindowPrt.document.close();
      WindowPrt.focus();
      WindowPrt.print();
      WindowPrt.close();
    } else {
      this.lineItemsToPrint.emit(this.lineItems);
    }

  }

}
