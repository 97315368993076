import {Component, Input, OnInit} from '@angular/core';
import {Branch} from '../../configuration-management/models/branch.interface';
import {BranchService} from '../../configuration-management/services/branch.service';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'app-select-branch',
  templateUrl: './select-branch.component.html',
  styleUrls: ['./select-branch.component.css']
})
export class SelectBranchComponent implements OnInit {

  branches: Branch[];
  formSearch: FormControl = new FormControl('');


  @Input() control: FormControl;
  @Input() label = 'Branch';
  @Input() allowAll = false;
  constructor(private branchService: BranchService) { }

  ngOnInit() {
    this.branchService.index().subscribe(res => {
      this.branches = res.sort((a, b) => a.name.localeCompare(b.name));
    });
  }

}
