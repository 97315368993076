import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UserAccount} from '../../configuration-management/models/user.interface';
import {AccountService} from '../../configuration-management/services/account.service';
import {FormControl} from '@angular/forms';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-select-staff',
  templateUrl: './select-staff.component.html',
  styleUrls: ['./select-staff.component.css']
})
export class SelectStaffComponent implements OnInit, OnDestroy {

  staffList: UserAccount[];
  selectedRecipientName: string;
  formSearch: FormControl = new FormControl('');

  @Input() control: FormControl;
  @Input() label: string;
  @Input() allowAll = false;

  subscriptions: Subscription;


  constructor(
    public accountService: AccountService
  ) {
  }

  ngOnInit() {
    this.subscriptions = this.control.valueChanges.subscribe(this.updateSelectedRecipientName.bind(this));
    this.subscriptions.add(this.accountService.getAllUsersObservable().subscribe(res => {
      const userIds: string[] = [];
      const userSet: UserAccount[] = [];
      res.forEach(user => {
        if (!userIds.includes(user.id)) {
          userIds.push(user.id);
          userSet.push(user);
        }
      });
      this.staffList = userSet.sort((a, b) => a.lastName.localeCompare(b.lastName));
      this.updateSelectedRecipientName();
    }));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  updateSelectedRecipientName() {
    if (this.control.value) {
      // There could be more than one user saved because of billing codes, so just grab the first
      const selected = this.staffList.filter(user => this.control.value === user.id)[0];
      this.selectedRecipientName = `${selected.firstName} ${selected.lastName}`;
    }
  }
}
