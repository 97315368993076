import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-view-invoice-dialog',
  templateUrl: './view-invoice.dialog.html',
  styleUrls: ['./view-invoice.dialog.scss']
})
export class ViewInvoiceDialogComponent implements OnInit {
  emailOption: boolean;

  constructor(
    public dialogRef: MatDialogRef<ViewInvoiceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }


  ngOnInit() {

  }

  onNoClick() {
    this.dialogRef.close();
  }
}
