import { Injectable } from '@angular/core';
import { BaseService } from '../../helpers/base.service';
import { LineItem } from '../models/line-item.interface';


@Injectable({
  providedIn: 'root',
})
export class PrintService extends BaseService {
  isPrinting = false;

  printLabels(lineItems: LineItem[]) {
    window.print();
  }
}
