import { Injectable } from '@angular/core';
import { BaseService } from '../../helpers/base.service';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { DiscountSummary } from '../models/discounts.interface';
import { dbContext } from 'src/app/shared/offline/offline-db.service';

@Injectable({
  providedIn: 'root',
})
export class DiscountsService extends BaseService {

  discounts: DiscountSummary[];
  public index(): Observable<DiscountSummary[]> {

    const route = `${this.apiUrl}/Discount`;

    return this.http.get<DiscountSummary[]>(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public async getActiveDiscounts() {


    if (this.discounts == null) {
      // tslint:disable-next-line: max-line-length
      this.discounts = (await dbContext.discounts.toArray()).filter(x => x.disabled === false && x.isExpired === false);
    }

    return this.discounts;
  }


  public create(model: any): Observable<any> {

    const route = `${this.apiUrl}/Discount/Create`;
    const body = JSON.stringify(model);

    return this.http.post<any>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public update(model: any): Observable<any> {

    const route = `${this.apiUrl}/Discount/Update`;
    const body = JSON.stringify(model);

    return this.http.post<any>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

}
