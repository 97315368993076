import { Injectable } from '@angular/core';
import { BaseService } from '../../helpers/base.service';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { LabSummary, LabSummaryListViewModel, Page } from '../models/labs.interface';
import { SyncService } from 'src/app/shared/offline/sync-service';
import { ConnectionService } from 'src/app/helpers/offline-detection';
import { HttpClient } from '@angular/common/http';
import { ConfigLoaderService } from 'src/app/config-loader.service';

@Injectable({
  providedIn: 'root',
})
export class LabsService extends BaseService {
  constructor(
    http: HttpClient,
    connectionService: ConnectionService,
    configService: ConfigLoaderService,
    public syncService: SyncService) {
    super(http, connectionService, configService);
  }

  apiLabs: LabSummaryListViewModel;
  apiLab: LabSummary;

  public destroy() {
    this.apiLabs = new LabSummaryListViewModel();
  }

  public get labList() {
    if (this.apiLabs !== undefined && this.isConnected) {
      return this.apiLabs;
      // } else if (this.syncService.clients !== undefined) {
      //   return this.syncService.clients;
    }
    return;
  }

  public get lab() {
    if (this.apiLab !== undefined && this.isConnected) {
      return this.apiLab;
      // } else if (this.syncService.client !== undefined) {
      //   return this.syncService.client;
    }
    return;
  }

  public getLab(page: Page) : Observable<any>{
    const route = `${this.apiUrl}/Lab/index`;
    const body = JSON.stringify({ page });

    return this.http.post<LabSummaryListViewModel>(route, page, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public disableHeskaTest(heskaTestId: string) : Observable<any>{
    const route = `${this.apiUrl}/Lab/DisableHeskaTest/${heskaTestId}`;
    return this.http.post<any>(route, null, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public clearLab() {
    this.apiLab = null;
    // this.syncService.client = null;
  }

  public index(): Observable<LabSummary[]> {
    const route = `${this.apiUrl}/Lab`;

    return this.http.get<LabSummary[]>(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public create(model: LabSummary): Observable<LabSummary> {
    const route = `${this.apiUrl}/Lab/Create`;
    const body = JSON.stringify(model);

    return this.http.post<LabSummary>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public update(model: LabSummary): Observable<LabSummary> {
    const route = `${this.apiUrl}/Lab/Update`;
    const body = JSON.stringify(model);

    return this.http.post<LabSummary>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  // Lab PDFS
  public uploadLabPDF(labId: string, fileToUpload: File): Observable<any> {
    const route = `${this.apiUrl}/Lab/${labId}/UploadLabPDF`;

    let formData: FormData = new FormData();
    formData.append('fileKey', fileToUpload, fileToUpload.name);


    return this.http.post<any>(route, formData).pipe(map(response => {
        return response;
    }));
  }

  public removeLabPDF(labId: string): Observable<LabSummary> {
    const route = `${this.apiUrl}/Lab/${labId}/UploadLabPDF`;


    return this.http.post<LabSummary>(route, null, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  getLabPDF(labId: string): any {
    const route = `${this.apiUrl}/Lab/${labId}/GetLabPDF`;
    return this.http.get(route, this.fileHeaders()).pipe(map(res => {
        return new Blob([res], { type: 'application/pdf' });
    }));
  }

  getPatientLabPDF(labId: string, patientId: string, doctorId: string): any {
    const route = `${this.apiUrl}/Lab/${labId}/LabPDF/${patientId}/${doctorId}`;
    return this.http.get(route, this.fileHeaders()).pipe(map(res => {
        return new Blob([res], { type: 'application/pdf' });
    }));
  }

}
