import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormGroupDirective,
  NgForm,
  Form,
} from "@angular/forms";
import { MatSnackBar } from "@angular/material";
import { ErrorStateMatcher } from "@angular/material/core";
import {
  MedicationsSuppliesSummary,
  MEDICATION_CATEGORIES,
} from "src/app/configuration-management/models/medicationsSupplies.interface";
import {
  UserAccount,
  UserAccounts,
} from "src/app/configuration-management/models/user.interface";
import { AccountService } from "src/app/configuration-management/services/account.service";
import { Request } from "src/app/requests-results/models/request.interface";
import { RequestService } from "src/app/requests-results/services/request.service";
import {
  LineItem,
  LineItemRequest,
} from "../../../../../../line-item-entry/models/line-item.interface";

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: "app-refills-form",
  templateUrl: "./refills-form.component.html",
  styleUrls: ["./refills-form.component.scss"],
})
export class RefillsFormComponent implements OnInit {
  @Output() model = new EventEmitter<LineItem>();
  @Output() isValid = new EventEmitter<boolean>();
  @Input() existingModel: LineItem;
  @Input() isEditable: boolean;
  modelInfo: LineItem;
  categories = MEDICATION_CATEGORIES;
  matcher = new MyErrorStateMatcher();
  userList: UserAccount[];
  userSearch: UserAccount[];
  currentUser: UserAccount;
  currentUserName: string;

  refillsForm = new FormGroup({
    refillStatus: new FormControl("", Validators.required),
    refillDate: new FormControl("", Validators.required),
    refillStatusDate: new FormControl("", Validators.required),
    note: new FormControl("", Validators.required),
  });

  constructor(
    public accountService: AccountService,
    public snackBar: MatSnackBar
    ) {}

  ngOnInit() {
    this.getUsers();
    if (this.existingModel) {
      if (!this.existingModel.lineItemRequest) {
        this.existingModel.lineItemRequest = new LineItemRequest();
        this.existingModel.lineItemRequest.refillDate = new Date();
        this.existingModel.lineItemRequest.refillStatusDate = this.existingModel.lineItemRequest.refillDate;
      }
      this.refillsForm = new FormGroup({
        refillStatus: new FormControl({
          value: this.existingModel.lineItemRequest.refillStatus,
          disabled: !this.isEditable
          },
          Validators.required
        ),
        refillDate: new FormControl({
          value: this.existingModel.lineItemRequest.refillDate,
          disabled: !this.isEditable
          },
          Validators.required
        ),
        refillStatusDate: new FormControl({
          value: this.existingModel.lineItemRequest.refillStatusDate,
          disabled: !this.isEditable
          },
          Validators.required
        ),
        note: new FormControl({
          value: this.existingModel.lineItemRequest.note,
          disabled: !this.isEditable
          }
        ),
      });
      this.modelInfo = this.existingModel;
    }
  }

  modelChanged() {
    this.mapForm();
  }

  getUsers() {
    this.accountService.getUserAccountsList().subscribe(
      (res) => {
        this.userList = [];
        res.accounts.forEach((x) => {
          if (x.role !== "Client") {
            this.userList.push(x);
          }
        });
        this.userSearch = this.userList;

        // if user already exists
        if (
          this.existingModel.lineItemRequest.refillStatusUserId !== null &&
          this.existingModel.lineItemRequest.refillStatusUserId !== undefined
        ) {
          this.currentUser = this.userList.find(
            (x) => x.id === this.existingModel.lineItemRequest.refillStatusUserId
          );
          this.currentUserName = this.currentUser.name;
        }
        // set to default user
        else {
          this.currentUser = this.userList.find(
            (x) => x.id === this.accountService.currentUserValue.id
          );
          this.currentUserName = this.currentUser.name;
          this.filterUsers();
        }
      },
      (error) => {
        this.snackBar.open(
          "ERROR: Offline, cannot access User Data",
          "WARNING",
          {
            duration: 2000,
          }
        );
      }
    );
  }

  setUser(user: string) {
    var findUser = this.userList.find((x) => x.name.toLowerCase() === user.toLowerCase());
    if (findUser) {
      this.currentUser = findUser;
      this.currentUserName = this.currentUser.name;
    } else {
      this.currentUser = null;
    }
    this.modelChanged();
  }

  filterUsers(){
    if (this.currentUserName == "") {
      this.userSearch = this.userList;
      this.setUser(this.currentUserName);
    } else {
      this.userSearch = this.userList.filter(x => x.name.toLowerCase().includes(this.currentUserName.toLowerCase()));
      this.setUser(this.currentUserName);
    }
  }

  mapForm() {
    var tempModelInfo = this.refillsForm.value as LineItemRequest;
    if (this.currentUser !== null) {
      this.modelInfo.lineItemRequest.refillStatusUserId = this.currentUser.id;
    }
    this.modelInfo.lineItemRequest.refillStatus = tempModelInfo.refillStatus;
    this.modelInfo.lineItemRequest.refillDate = tempModelInfo.refillDate;
    this.modelInfo.lineItemRequest.refillStatusDate = tempModelInfo.refillStatusDate;
    this.modelInfo.lineItemRequest.note = tempModelInfo.note;

    this.model.emit(this.modelInfo);
    this.isValid.emit(this.refillsForm.valid && this.currentUser !== null);
  }
}
