import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RefillsDialogData, Request } from 'src/app/requests-results/models/request.interface';
import { RequestService } from 'src/app/requests-results/services/request.service';
import { LineItem, LineItemRequest } from '../../../../../line-item-entry/models/line-item.interface';

@Component({
  selector: 'app-refills-dialog',
  templateUrl: './refills.dialog.html',
  styleUrls: ['./refills.dialog.scss']
})
export class RefillsDialogComponent implements OnInit {
  model: LineItem;
  canSubmit = false;
  isEditable: boolean;

  constructor(
    public dialogRef: MatDialogRef<RefillsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: RefillsDialogData,
    public requestService: RequestService,
    public snackBar: MatSnackBar,
  ) { }

  ngOnInit() {
    this.isEditable = this.data.IsEditable;
    this.model = JSON.parse(JSON.stringify(this.data.LineItem));
  }

  save(_model: LineItem) {
    this.dialogRef.close(this.model);
  }

  validate(isValid) {
    this.canSubmit = isValid;
  }

  onNoClick() {
    this.dialogRef.close();
  }
}
