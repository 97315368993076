import {Component, Input, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'app-medical-record-status',
  templateUrl: './medical-record-status.component.html',
  styleUrls: ['./medical-record-status.component.css']
})
export class MedicalRecordStatusComponent implements OnInit {

  @Input() control: FormControl;

  constructor() { }
  readonly medicalRecordStatusList: MedicalRecordStatus[] = [
    {name: 'All', value: ''},
    {name: 'Unfinished', value: 'Unfinished'},
    {name: 'Finished', value: 'Finished'},
    {name: 'Other', value: 'Other'},
  ];

  ngOnInit() {
  }

}

interface MedicalRecordStatus {
  name: string;
  value: string;
}
