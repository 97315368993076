import { Component, Inject, OnInit } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LineItem } from '../../../../../line-item-entry/models/line-item.interface';
import { DiscountsService } from '../../../../../configuration-management/services/discounts.service';
import { AccountService } from '../../../../../configuration-management/services/account.service';
import { UserAccount } from 'src/app/configuration-management/models/user.interface';
import { DiscountSummary } from 'src/app/configuration-management/models/discounts.interface';

@Component({
  selector: 'app-group-invoice-items',
  templateUrl: 'group-invoice-items.dialog.html',
  styleUrls: ['./group-invoice-items.dialog.scss']
})
export class GroupInvoiceItemsComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<LineItem[]>,
    public discountsService: DiscountsService,
    public accountService: AccountService,
    @Inject(MAT_DIALOG_DATA) public data: LineItem[]
  ) { }

  ngOnInit() {

  }

  remove(item: LineItem) {
    this.data = this.data.filter(x => x !== item);
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

}
