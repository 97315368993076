import { Component, Inject, OnInit } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { InvoiceService } from '../../../../line-item-entry/services/invoice.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Payment, Statement } from 'src/app/list-communications/models/statement.interface';
import { StatementService } from 'src/app/list-communications/services/statements.service';
import { ClientService } from 'src/app/client-management/services/client.service';
import { Invoice } from '../../../../line-item-entry/models/invoice.interface';

@Component({
  selector: 'app-payment-dialog',
  templateUrl: 'payment.dialog.html',
  styleUrls: ['./payment.dialog.scss']
})
export class PaymentDialogComponent implements OnInit {
  payment = new Payment();
  statement: Statement;
  paymentForm = new FormGroup({
    amount: new FormControl(0, Validators.required),
    datePaid: new FormControl(new Date(Date.now()), Validators.required),
    paymentType: new FormControl('', Validators.required),
    paidTowards: new FormControl(''),
    note: new FormControl('')

  });
  recentInvoices: any[];
  selectedInvoice: any;

  constructor(
    public dialogRef: MatDialogRef<any>,
    public invoiceService: InvoiceService,
    public statementService: StatementService,
    public clientService: ClientService,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: PaymentData,
  ) { }


  ngOnInit() {

    this.getStatement();
    this.clientService.getRecentInvoices(this.data.clientId).subscribe(x => {

      this.recentInvoices = x;
    });
  }

  getPaymentDetails() {
    this.statementService.getPayment(this.data.paymentId).subscribe(res => {
      this.payment = res;
      let paidTowards = '';

      if (this.payment.invoiceId) {
        paidTowards = 'Invoice';
        this.selectedInvoice = this.recentInvoices.find(x => x.id === this.payment.invoiceId);
      }
      this.paymentForm = new FormGroup({
        amount: new FormControl(this.payment.amount, Validators.required),
        datePaid: new FormControl(this.payment.datePaid, Validators.required),
        paymentType: new FormControl(this.payment.paymentType, Validators.required),
        paidTowards: new FormControl(paidTowards),
        note: new FormControl(this.payment.note)

      });
    });
  }

  getStatement() {
    this.statementService.getStatementByClient(this.data.clientId).subscribe(x => {
      this.statement = x;

      if (this.data.paymentId) {

        this.getPaymentDetails();

      } else {
        this.paymentForm = new FormGroup({
          amount: new FormControl(this.statement.balanceDue.toFixed(2), Validators.required),
          datePaid: new FormControl(new Date(Date.now()), Validators.required),
          paymentType: new FormControl('', Validators.required),
          paidTowards: new FormControl(''),
          note: new FormControl('')

        });
      }


    });
  }

  modelChanged() {
    this.payment.amount = this.paymentForm.value.amount;
    this.payment.datePaid = this.paymentForm.value.datePaid;
    this.payment.paymentType = this.paymentForm.value.paymentType;
    this.payment.note = this.paymentForm.value.note;
    if (this.paymentForm.value.paidTowards === 'Invoice') {
      this.payment.invoiceId = this.selectedInvoice.id;
      this.payment.invoiceNumber = this.selectedInvoice.number;
    }
    this.payment.clientId = this.data.clientId;


  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  save() {
    this.modelChanged();

    this.statementService.pay(this.payment).subscribe(
      response => {
        this.snackBar.open('Payment successfully recorded.', 'Success', {
          duration: 2000,
        });
        this.dialogRef.close(true);
      },
      error => {

        this.snackBar.open('Payment could not be made.', 'Error', {
          duration: 2000,
        });
      }
    );
  }
  update() {
    this.modelChanged();

    this.statementService.updatePayment(this.payment, this.data.paymentId).subscribe(
      response => {
        this.snackBar.open('Payment successfully updated.', 'Success', {
          duration: 2000,
        });
        this.dialogRef.close(true);
      },
      error => {

        this.snackBar.open('Payment could not be updated.', 'Error', {
          duration: 2000,
        });
      }
    );
  }

  delete() {


    this.statementService.deletePayment(this.data.paymentId).subscribe(
      response => {
        this.snackBar.open('Payment was deleted.', 'Success', {
          duration: 2000,
        });
        this.dialogRef.close(true);
      },
      error => {

        this.snackBar.open('Payment could not be deleted.', 'Error', {
          duration: 2000,
        });
      }
    );
  }

}

export class PaymentData {
  clientId: string;
  paymentId: string;
}
