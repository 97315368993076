import { Component, OnInit, Input } from '@angular/core';
import { LineItem } from '../../../../../line-item-entry/models/line-item.interface';
import { PrintService } from '../../../../../line-item-entry/services/print.service';
import { Client } from 'src/app/client-management/models/client.interface';
import { Patient } from 'src/app/patient-management/models/patient.interface';

@Component({
  selector: 'app-print-label',
  templateUrl: './print-label.component.html',
  styleUrls: ['./print-label.component.scss']
})
export class PrintLabelComponent implements OnInit {
  // tslint:disable-next-line: variable-name
  _lineItems: LineItem[];
  // tslint:disable-next-line: variable-name
  _client: Client;
  // tslint:disable-next-line: variable-name
  _patient: Patient;

  @Input()
  set lineItems(lineItems: LineItem[]) {

    this._lineItems = lineItems;


  }

  getItemName(item: LineItem): string{
    const str1 = /\((.*?)\) ../g;


    return item.name.replace(str1, '');
  }

  @Input()
  set client(client: Client) {

    this._client = client;
  }

  @Input()
  set patient(patient: Patient) {

    this._patient = patient;
  }

  constructor(public printService: PrintService) { }

  ngOnInit() {

  }
}
