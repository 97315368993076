import { Injectable } from '@angular/core';
import { BaseService } from '../../helpers/base.service';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { LocationSummary } from '../models/locations.interface';
import { dbContext } from '../../shared/offline/offline-db.service';
import {from} from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class LocationService extends BaseService {

  public inventoryLocations: LocationSummary[];
  public index(): Observable<any> {

    const route = `${this.apiUrl}/Location`;

    return this.http.get<any>(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }


  public async getInventoryLocations() {


    if (this.inventoryLocations == null) {
      // tslint:disable-next-line: max-line-length
      this.inventoryLocations = (await dbContext.inventoryLocations.toArray()).filter(x => x.disabled === false && x.hasInventory === true).sort((as, bs) => {
        var a: any, b: any, a1: number, b1: number, i = 0, n: number, L,
          rx = /(\.\d+)|(\d+(\.\d+)?)|([^\d.]+)|(\.\D+)|(\.$)/g;
        if (as.name === bs.name) return 0;
        a = as.name.toLowerCase().match(rx);
        b = bs.name.toLowerCase().match(rx);
        L = a.length;
        while (i < L) {
          if (!b[i]) return 1;
          a1 = a[i],
            b1 = b[i++];
          if (a1 !== b1) {
            n = a1 - b1;
            if (!isNaN(n)) return n;
            return a1 > b1 ? 1 : -1;
          }
        };
      });
    };

    return this.inventoryLocations;
  }

  public getBranchForLocation(locationId: string) {
    const branchIdPromise = this.getInventoryLocations().then(locations => locations.find(location => location.id === locationId).branchId);
    return from(branchIdPromise);
  }



  public create(model: any): Observable<any> {

    const route = `${this.apiUrl}/Location/Create`;
    const body = JSON.stringify(model);

    return this.http.post<any>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public update(model: any): Observable<any> {

    const route = `${this.apiUrl}/Location/Update`;
    const body = JSON.stringify(model);

    return this.http.post<any>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public getByName(name: string): Observable<any> {

    const route = `${this.apiUrl}/Location/GetByName/${name}`;
    const body = JSON.stringify(name);

    return this.http.post<any>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }
}
