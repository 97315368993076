import { LabResult, Order } from 'src/app/whiteboard/models/order.interface';

export class Page {
  size = 200;
  totalElements = 0;
  totalPages = 0;
  pageNumber = 0;
  hasFilter = false;
  filter = '';
}

export class LabSummaryListViewModel {
  labs: Array<LabSummary>;
  page = new Page();
  isOffline: boolean;
}

export class HeskaTest {
  id: string;
  testType: string;
  description: string;
}

export interface LabSummary {
  id: string;
  name: string;
  defaultQuantity: string;
  minimumQuantity: string;
  price: string;
  code: string;
  cost: number;
  disabled: boolean;
  hasAttachment: boolean;
  s3FileId: string;
  heskaTests: Array<HeskaTest>;
}

export class LabLabResult {
  id: string = null;
  labId: string;
  lab: LabSummary;
  labResultId: string;
  labResult: LabResult = null;
  disabled: boolean;
  order:number;

}
