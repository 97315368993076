import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Invoice } from '../../../../../line-item-entry/models/invoice.interface';
import { InvoiceStatusValue } from 'src/app/shared/invoice-status/invoice-status-value';

@Component({
  selector: 'app-invoice-selection',
  templateUrl: 'invoice-selection.dialog.html',
  styleUrls: ['./invoice-selection.dialog.scss']
})
export class InvoiceSelectionComponent implements OnInit {
  invoiceStatus: string = InvoiceStatusValue.NotReady;

  constructor(
    public dialogRef: MatDialogRef<string[]>,
    @Inject(MAT_DIALOG_DATA) public data: Invoice[]
  ) { }

  selectedInvoice = new Invoice();

  ngOnInit() {

  }

  setEmptyInvoice() {
    this.selectedInvoice = new Invoice();
    this.selectedInvoice.invoiceStatus = this.invoiceStatus;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  setInvoiceStatus() {
    this.selectedInvoice.invoiceStatus = this.invoiceStatus;
  }
}
